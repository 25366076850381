<template>
  <div>
    <v-btn
      icon
      class="mt-3"
      color="success"
      @click="createApplicationNoteAndAttachmentDialog = true"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>

    <v-dialog
      v-if="createApplicationNoteAndAttachmentDialog"
      v-model="createApplicationNoteAndAttachmentDialog"
      persistent
      max-width="720"
    >
      <v-card>
        <v-card-title class="text-h5 transparent-3">
          Create Application Notes & Attachments
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            icon
            @click="createApplicationNoteAndAttachmentDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="my-3 mt-6">
          <v-form id="form" @submit.prevent v-model="isValidForm" ref="from">
            <FormMaker
              :col="{ cols: 12 }"
              :model="form_data"
              :schema="form_structure"
              :id="'notes_and_attachment_form'"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="transparent-2">
          <v-btn
            @click="addNoteOrAttachmentToApplication()"
            color="green darken-1"
            outlined
            block
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FormMaker from "@/components/FormMaker";
import { required, minLen } from "@/plugins/validationRules.js";
export default {
  components: { FormMaker },
  props: {
    application: { required: true, default: null },
  },
  data() {
    return {
      isValidForm: false,
      form_data: {
        note_details: {
          title: null,
          body: null,
        },
        file_data: null,
      },
      createApplicationNoteAndAttachmentDialog: false,
    };
  },
  mounted() {},
  computed: {
    form_structure() {
      return {
        note_details: {
          title: {
            type: "text",
            name: "title",
            label: "Title",
            clearable: true,
            col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            rules: [minLen(3)],
          },
          body: {
            type: "textarea",
            name: "body",
            label: "Body",
            clearable: true,
            col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            rules: [minLen(10)],
          },
        },
        file_data: {
          type: "file",
          name: "file_data",
          label: "Attachment File",
          clearable: true,
          col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
          rules: [],
        },
      };
    },
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    async addNoteOrAttachmentToApplication() {
      let data = {
        session_key: this.sessionKey,
        application_id: this.application.id,
        ...this.form_data,
      };

      data = this.$helpers.jsonToFormData(data);

      return await this.post(
        `${this.baseUrl}/agent-portal/add-note-or-attachment-to-application`,
        data,
        true
      ).then((res) => {
        if (res) {
          this.showMessageFromResponseDta(res);

          if (res.code == 200) {
            this.refresh();
            this.createApplicationNoteAndAttachmentDialog = false;
          }
        }

        return res;
      });
    },
  },
};
</script>

<style lang="scss">
#notes_and_attachment_form .input-custom-title {
  display: none;
}
</style>
