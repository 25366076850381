<template>
	<v-main class="mt-10">
		<router-view />

		<dashboard-core-footer />
	</v-main>
</template>

<script>
	export default {
		name: "DashboardCoreView",

		components: {
			DashboardCoreFooter: () => import("./Footer")
		}
	};
</script>
