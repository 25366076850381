<template>
  <v-flex xs12 sm6>
    <LoginForm :is_page="true" />
  </v-flex>
</template>

<script>
import LoginForm from "@/components/forms/login";

export default {
  components: { LoginForm },
  data: () => {
    return {};
  },
  head: {
    title: function () {
      return {
        inner: "Login",
      };
    },
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
</style>
