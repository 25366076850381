<template>
  <div v-if="$store.state.specialLoading" class="loading">
    <div class="rocket"></div>
    <h3 class="text">
      {{ textList[currentTextIndex] }}
    </h3>
  </div>
</template>

<script>
var intervalId = null;
export default {
  props: {},
  data() {
    return {
      currentTextIndex: 0,
      textList: [
        "Verifying property Information...",
        "Evaluating your flood risk...",
        "Quoting all eligible insurance companies...",
        "Calculating lowest rates...",
      ],
    };
  },
  mounted() {},
  watch: {
    "$store.state.specialLoading"(val) {
      if (val) {
        this.textChanger();
      } else {
        clearInterval(intervalId);
      }
    },
  },
  methods: {
    textChanger() {
      let $this = this;
      $this.currentTextIndex = 0;
      intervalId = setInterval(function () {
        if ($this.currentTextIndex == 3) $this.currentTextIndex = 0;
        else $this.currentTextIndex = $this.currentTextIndex + 1;
      }, 1500);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.loading {
  z-index: 9999;
  left: 0;
  right: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(126, 126, 126, 0.8) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .rocket {
    background-image: url("/images/loading-rocket.gif");
    background-color: transparent !important;
    background-position: center center;
    height: 40%;
    max-height: auto;
    width: 20vw;
    overflow: hidden;
    background-size: 100%;
  }
  .text {
    color: white !important;
  }
  // .text::before {
  //   content: "Verifying property Information...";
  //   -webkit-animation: textAnim 8s linear infinite; /* Safari */
  //   animation: textAnim 8s linear infinite;
  // }
}

@keyframes textAnim {
  0% {
    content: "Verifying property Information...";
  }
  33% {
    content: "Evaluating your flood risk...";
  }
  66% {
    content: "Quoting all eligible insurance companies...";
  }
  99% {
    content: "Calculating lowest rates...";
  }
}
</style>
