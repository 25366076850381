<template>
	<div>
		<dashboard-core-app-bar />

		<dashboard-core-drawer />

		<dashboard-core-view />

		<!-- <dashboard-core-settings /> -->
	</div>
</template>

<script>
	import DashboardCoreAppBar from "@/components/dashboard-core/AppBar";
	import DashboardCoreDrawer from "@/components/dashboard-core/Drawer";
	import DashboardCoreView from "@/components/dashboard-core/View";
	export default {
		name: "DashboardIndex",

		components: {
			DashboardCoreAppBar,
			DashboardCoreDrawer,
			DashboardCoreView
		},

		data: () => ({
			expandOnHover: false
		}),
		created() {},
		beforeDestroy() {}
	};
</script>
