<template>
	<div>
		<v-btn class="mt-3" icon color="secondary" @click="showDialog()">
			<v-icon>mdi-pencil-outline</v-icon>
		</v-btn>

		<DialogBase
			:dialogWidth="900"
			:dialogModel="dialogModel"
			@closeDialog="(val) => closeDialog(val)"
			scrollable
		>
			<template v-slot:title>
				Create Custom Quote Application
				{{ application.application_id }}
			</template>

			<v-form
				id="form"
				@submit.prevent
				v-model="isValidForm"
				ref="from"
				class="pt-4"
			>
				<VFormBase :schema="form_structure" :model="form_data"></VFormBase>
			</v-form>
			<template v-slot:footer>
				<v-btn color="accent" outlined block @click="submit()">
					Create/Update
				</v-btn>
			</template>
		</DialogBase>
	</div>
</template>

<script>
	import { required, minLen } from "@/plugins/validationRules.js";

	import VFormBase from "vuetify-form-base";
	import DialogBase from "@/components/dialogs/Base";
	export default {
		components: { DialogBase, VFormBase },
		props: {
			application: { required: true, default: null },
		},
		data() {
			return {
				dialogModel: false,
				isValidForm: false,
				form_data: {},
				form_data_default: {
					carrier_name: null,
					premium: null,
					description: "Custom Quote!",
					carrier_url: null,
				},
			};
		},
		mounted() {
			this.form_data = Object.assign({}, this.form_data_default);
		},
		computed: {
			form_structure() {
				let structure = {
					carrier_name: {
						type: "text",
						name: "carrier_name",
						label: "Carrier Name",
						...this.$helpers.formFieldsBasicLayout,
						col: { cols: 12, sm: 12, md: 12, lg: 6, xl: 6 },
						rules: [required()],
					},
					premium: {
						type: "text",
						ext: "number",
						name: "premium",
						label: "Premium",
						...this.$helpers.formFieldsBasicLayout,
						col: { cols: 12, sm: 12, md: 12, lg: 6, xl: 6 },
						rules: [required()],
					},
					carrier_url: {
						type: "text",
						name: "carrier_url",
						label: "Carrier Url",
						...this.$helpers.formFieldsBasicLayout,
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
						rules: [],
					},
					description: {
						type: "textarea",
						name: "description",
						label: "Description",
						...this.$helpers.formFieldsBasicLayout,
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
						rules: [required()],
					},
				};

				return structure;
			},
		},
		methods: {
			refresh() {
				this.$emit("refresh");
			},
			showDialog() {
				this.dialogModel = true;
				this.form_data = Object.assign({}, this.form_data_default);
			},
			closeDialog(val) {
				this.dialogModel = false;
				this.form_data = Object.assign({}, this.form_data_default);
			},
			async submit() {
				this.$refs.from.validate();
				if (!this.isValidForm) return;

				let data = {
					application_id: this.application.id,
					...this.form_data,
				};

				return await this.post(
					`${this.baseUrl}/agent-portal/create-application-custom-quote`,
					data,
					true
				).then((data) => {
					if (data == "undefined") return;
					this.showMessageFromResponseDta(data);

					if (data.code == 200) {
						this.refresh();
						this.closeDialog();
					}
				});
			},
		},
	};
</script>
