<template>
  <div>
    <v-card-title>
      <v-text-field
        append-icon="mdi-magnify"
        v-model="search"
        label="Search"
        hide-details
        single-line
        clearable
      ></v-text-field>
      <v-btn icon class="mt-3" color="primary" @click="getApplicationNotes()">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :sort-by="[]"
        :sort-desc="[false]"
        :headers="headers"
        :items="notes_filtered"
        :search="search"
        :items-per-page="-1"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 20, -1],
        }"
      >
        <template v-slot:item.key="{ item }">
          <div class="text-capitalize">
            {{ item.key.toString().replace(/_/g, " ") }}
          </div>
        </template>
        <template v-slot:item.payload="{ item }">
          <div class="text-capitalize">
            <template v-for="(dis, key) in item.payload">
              <v-row
                v-if="!['is_alert', 'is_active'].includes(key)"
                no-gutters
                :key="key"
              >
                <v-col v-if="key == 'sent_at'">
                  {{ key.toString().replace(/_/g, " ") }}:
                  {{ item.created_at }}
                </v-col>
                <v-col v-else>
                  {{ key ? key.toString().replace(/_/g, " ") : key }}:
                  {{ dis ? dis.toString().replace(/_/g, " ") : dis }}
                </v-col>
              </v-row>
            </template>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <div>
            <v-btn
              v-if="item.payload.is_alert"
              :color="item.payload.is_active ? 'error' : 'success'"
              @click="toggleApplicationAlertNote(item)"
              icon
              :title="
                item.payload.is_active
                  ? 'Click To Active'
                  : 'Click To Deactivate'
              "
            >
              <v-icon>{{
                item.payload.is_active
                  ? "mdi-bell-outline"
                  : "mdi-bell-off-outline"
              }}</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},
  props: {},
  data() {
    return {
      notes: [],
      search: null,
      headers: [
        {
          sortable: true,
          text: "Note Key",
          value: "key",
          align: "left",
        },
        {
          sortable: true,
          text: "Description",
          value: "payload",
          align: "left",
        },
        {
          sortable: false,
          text: "Actions",
          value: "actions",
          align: "right",
        },
      ],
    };
  },
  created() {
    this.getApplicationNotes();
  },
  computed: {
    ...mapState({
      application: (state) => state.application.application,
    }),
    notes_filtered() {
      return this.notes;
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    async getApplicationNotes() {
      this.$store
        .dispatch(
          "application/getApplicationById",
          this.$route.params.application_id
        )
        .then((res) => {
          if (res?.code == 200)
            this.notes = res?.data?.application?.notes || [];
          else this.$router.push({ name: "agent-portal" });
        });
    },
    async toggleApplicationAlertNote(note) {
      let data = {
        note_id: note.id,
        application_id: this.application.id,
      };
      return await this.post(
        `${this.baseUrl}/agent-portal/toggle-application-alert-note`,
        data,
        true
      ).then((data) => {
        if (data) {
          this.showMessageFromResponseDta(data);
          this.getApplicationNotes();
        }
        return data;
      });
    },
  },
};
</script>
