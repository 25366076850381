<template>
	<v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
		<v-btn
			class="mr-3"
			elevation="1"
			fab
			small
			@click="setNavigationDrawer(!navigationDrawer)"
		>
			<v-icon v-if="value"> mdi-view-quilt </v-icon>

			<v-icon v-else> mdi-dots-vertical </v-icon>
		</v-btn>

		<v-toolbar-title
			class="hidden-sm-and-down font-weight-light text-uppercase"
			v-text="currentUserRole + ' Dashboard'"
		/>

		<v-btn
			@click="loginToPreviousUserAccount()"
			v-if="previousUserRole == 'admin'"
			title="Click to login back to Previous Account"
			color="purple"
			icon
		>
			<v-icon>mdi-login</v-icon>
		</v-btn>

		<v-spacer />

		<v-text-field
			style="max-width: 250px"
			color="secondary"
			:label="'Search'"
			outlined
			hide-details
			dense
		>
			<template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
				<v-btn class="mt-n2" elevation="1" fab small>
					<v-icon>mdi-magnify</v-icon>
				</v-btn>
			</template>
		</v-text-field>

		<v-menu
			bottom
			left
			offset-y
			origin="top right"
			transition="scale-transition"
		>
			<template v-slot:activator="{ attrs, on }">
				<!-- <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>5</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn> -->

				<v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
					<v-icon>mdi-dots-vertical</v-icon>
				</v-btn>
			</template>

			<v-list :tile="false" nav>
				<base-item
					:item="{
						title: 'Profile',
						icon: 'mdi-account',
						to: { name: 'user-profile' },
					}"
				/>
				<base-item
					:item="{
						title: 'Dashboard',
						icon: 'mdi-view-dashboard',
						to: { name: 'dashboard-home' },
					}"
				/>
				<base-item
					:item="{
						title: 'Logout',
						icon: 'mdi-logout',
						to: { name: 'logout' },
					}"
				/>
			</v-list>
		</v-menu>
	</v-app-bar>
</template>

<script>
	// Components
	import { VHover, VListItem } from "vuetify/lib";

	// Utilities
	import { mapState, mapMutations } from "vuex";

	export default {
		name: "DashboardCoreAppBar",
		components: {
			AppBarItem: {
				render(h) {
					return h(VHover, {
						scopedSlots: {
							default: ({ hover }) => {
								return h(
									VListItem,
									{
										attrs: this.$attrs,
										class: {
											"black--text": !hover,
											"white--text secondary elevation-12": hover,
										},
										props: {
											activeClass: "",
											dark: hover,
											link: true,
											...this.$attrs,
										},
									},
									this.$slots.default
								);
							},
						},
					});
				},
			},
		},
		props: {
			value: {
				type: Boolean,
				default: false,
			},
		},
		data: () => ({
			notifications: [
				"Mike John Responded to your email",
				"You have 5 new tasks",
				"You're now friends with Andrew",
				"Another Notification",
				"Another one",
			],
		}),
		computed: {
			...mapState(["navigationDrawer"]),
		},
		methods: {
			...mapMutations({
				setNavigationDrawer: "setNavigationDrawer",
			}),
			async loginToPreviousUserAccount() {
				if (
					!confirm("Are you sure you want to login back to the previous account?")
				)
					return false;
				await this.$store.dispatch("auth/loginToPreviousUserAccount");
			},
		},
	};
</script>
