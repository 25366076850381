import http from "@/plugins/http";
import router from "@/router";
import authStorage from "@/plugins/auth";

const auth = {
  namespaced: true,
  state: () => ({
    user: null,
    session_key: null,
    user_role: null,
    authenticated: false,
    user_notifications: null,
    user_settings: null,
    previous_user_data: null,
  }),
  mutations: {
    setCurrentUser(state, value) {
      state.user = value;
    },
    setUserNotifications(state, value) {
      state.user_notifications = value;
    },
    setUserSettings(state, value) {
      state.user_settings = value;
    },
    setAuthenticated(state, value) {
      state.authenticated = value;
    },
    setSessionKey(state, value) {
      state.session_key = value;
    },
    setUserRole(state, value) {
      state.user_role = value;
    },
    set_previous_user_data(state, value) {
      state.previous_user_data = value;
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    user_notifications(state) {
      return state.user_notifications;
    },
    user_settings(state) {
      return state.user_settings;
    },
    authenticated(state) {
      return state.authenticated;
    },
    session_key(state) {
      return state.session_key;
    },
    user_role(state) {
      return state.user_role;
    },
    get_previous_user_data(state) {
      return state.previous_user_data;
    },
  },
  actions: {
    login({ commit, rootState }, data) {
      var user = helpers.isJson(data.user) ? JSON.parse(data.user) : data.user;

      commit("setCurrentUser", user);
      commit("setSessionKey", data.session_key);
      commit("setUserRole", data.user_role);
      commit("setAuthenticated", true);
      if (user.agent) rootState.selected_agent_id = user.agent.id;
    },
    logout({ commit }) {
      commit("setCurrentUser", null);
      commit("setSessionKey", null);
      commit("setUserRole", null);
      commit("setAuthenticated", false);
    },
    async getUserNotifications({ state, commit }) {
      var data = {
        session_key: state.session_key,
        user_id: state?.user?.id,
      };
      return await http
        .post(helpers.baseUrl() + "/get-user-notifications", data)
        .then(
          (response) => {
            if (response.data.code == 200)
              commit(
                "setUserNotifications",
                response.data.data.user_notifications
              );
            else {
              console.log("Error user notifications", response.data);
            }
            return response;
          },
          (error) => {
            console.log("Error user notifications", error);
            return error;
          }
        );
    },
    async getUserSettings({ state, commit }) {
      var data = {
        user_id: state?.user?.id,
        session_key: state?.session_key,
      };
      return await http
        .post(helpers.baseUrl() + "/get-user-settings", data)
        .then(
          (response) => {
            if (response.data.code == 200)
              commit("setUserSettings", response.data.data.user_settings);
            else {
              console.log("Error getting user settings", response.data);
            }
            return response;
          },
          (error) => {
            console.log("Error getting user settings", error);
            return error;
          }
        );
    },
    async updateUserSettings({ state, commit }, { payload }) {
      var data = {
        user_id: state?.user?.id,
        session_key: state.session_key,
        payload,
      };
      return await http
        .post(helpers.baseUrl() + "/update-user-settings", data)
        .then(
          (response) => {
            if (response.data.code == 200)
              commit("setUserSettings", response.data.data.user_settings);
            else {
              console.log("Error updating user setting", response.data);
            }
            return response;
          },
          (error) => {
            console.log("Error updating user setting", error);
            return error;
          }
        );
    },
    async toggleUserQuoteAutoEmailing({ state, commit }) {
      var data = {
        session_key: state.session_key,
        user_id: state.user?.id,
      };
      return await http
        .post(helpers.baseUrl() + "/toggle-user-quote-auto-emailing", data)
        .then(
          (response) => {
            if (response.data.code == 200)
              commit("setUserSettings", response.data.data.user_settings);
            else {
              console.log("Error updating setting", response.data);
            }
            return response;
          },
          (error) => {
            console.log("Error updating setting", error);
            return error;
          }
        );
    },
    async getFreshUserData({ state, dispatch }) {
      var data = {
        session_key: state.session_key,
      };
      return await http
        .post(helpers.baseUrl() + "/get-user-fresh-data", data)
        .then(
          (response) => {
            if (response.data.code == 200)
              dispatch("updateUser", response.data.data);
            else if (response.data.code == 401) {
              router.push({
                name: "logout",
              });
            }
            return response;
          },
          (error) => {
            console.log(error);
            return error;
          }
        );
    },
    updateUser({ commit, state }, userData) {
      var user = helpers.isJson(userData) ? JSON.parse(userData) : userData;

      // if (user.profile_image == null)
      //   user.profile_image = "/profile_images/profile-placeholder.png";

      window.localStorage.setItem("user", JSON.stringify(user));
      commit("setCurrentUser", user);
    },
    async loginWithUserId({ commit, state, rootState }, { user_id }) {
      return await http
        .post(helpers.baseUrl() + "/login-with-user-id", {
          session_key: state.session_key,
          user_id,
        })
        .then(
          (response) => {
            if (response.data.code == 200) {
              commit("setLoading", true);
              authStorage.saveCurrentUseAsPreviousUserData();

              authStorage.logout();

              authStorage.login(
                response.data.data.session.session_key,
                response.data.data.user,
                response.data.data.user.role
              );

              router.replace({ name: "account" });

              setTimeout(function () {
                location.reload();
              }, 1000);
            } else {
              console.log("Error :", response.data);
            }
            return response;
          },
          (error) => {
            console.log("Error :", response.data);
            return error;
          }
        );
    },
    loginToPreviousUserAccount({ commit, state, rootState }) {
      let previous_user_data = state.previous_user_data;
      if (
        previous_user_data?.user &&
        previous_user_data?.session_key &&
        previous_user_data?.user_role
      ) {
        commit("setLoading", true);
        authStorage.logout();

        authStorage.login(
          previous_user_data.session_key,
          previous_user_data.user,
          previous_user_data.user_role
        );

        router.replace({ name: "account" });

        authStorage.unsetPreviousUserData();

        setTimeout(function () {
          location.reload();
        }, 1000);
      }
    },
  },
};

export default auth;
