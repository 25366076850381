var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":"","single-line":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mt-3",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.getApplicationNotesAndAttachments()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('createApplicationNoteAndAttachmentBtn',{attrs:{"application":_vm.application},on:{"refresh":function($event){return _vm.getApplicationNotesAndAttachments()}}})],1),_c('v-card-text',[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"4px"}},[_vm._l((_vm.application.zoho_crm_modules),function(zoho_crm_module,index){return [(index == 0)?[_c('div',{key:index,staticClass:"d-flex flex-row items-center justify-between transparent-2 pa-1"},[_c('div',{staticClass:"d-flex flex-grow-1"},[_vm._v(" "+_vm._s(zoho_crm_module.zoho_module_name)+" ")]),_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(zoho_crm_module.zoho_module_instance_id)+" ")])])]:_vm._e()]})],2),_c('v-data-table',{attrs:{"sort-by":[],"sort-desc":[false],"headers":_vm.headers,"items":_vm.notes_and_attachments_filtered,"items-per-page":-1,"footer-props":{
        itemsPerPageOptions: [5, 10, 20, -1],
      }},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
      var item = ref.item;
return [(_vm.isAttachment(item))?_c('div',{staticClass:"cursor-pointer primary--text text-underline",on:{"click":function($event){return _vm.downloadAttachment(item)}}},[_vm._v(" "+_vm._s(item.File_Name || "N/A")+" ")]):_c('div',[_vm._v(" "+_vm._s(item.Note_Title || "N/A")+" ")])]}},{key:"item.body",fn:function(ref){
      var item = ref.item;
return [_c('div',[_c('pre',[_vm._v(_vm._s(item.Note_Content || "N/A"))])])]}},{key:"item.attachment",fn:function(ref){
      var item = ref.item;
return [(item.$attachments && item.$attachments.length)?_c('div',[_vm._l((item.$attachments),function(attachment,index){return [_c('div',{key:index,staticClass:"cursor-pointer primary--text text-underline",on:{"click":function($event){return _vm.downloadAttachment(attachment)}}},[_vm._v(" "+_vm._s(attachment.File_Name)+" ")])]})],2):_c('div',[_vm._v("N/A")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }