<template>
	<div
		v-bind="$attrs"
		class="d-flex flex-column copy-list-container transparent-2"
		:class="$attrs.isLongList ? 'is-long-list' : 'is-small-list'"
	>
		<template v-for="(item, index) in items">
			<div class="d-flex" :key="index">
				<div v-html="getItemText(item)"></div>
				<v-spacer></v-spacer>
				<v-btn color="info" @click="copyItemValue(item)" icon small>
					<v-icon small>
						mdi-content-copy
					</v-icon>
				</v-btn>
			</div>
		</template>
	</div>
</template>

<script>
	export default {
		components: {},
		props: ["value"],
		data() {
			return {
				editorContext: null,
				content: null
			};
		},
		mounted() {
			this.content = this.value;
		},
		watch: {
			value(newVal, oldVal) {
				if (newVal != oldVal) this.content = newVal;
			},
			content(newVal, oldVal) {
				if (newVal != oldVal) this.$emit("input", newVal);
			}
		},
		computed: {
			items() {
				return this.$attrs.items || [];
			}
		},
		methods: {
			getItemText(item) {
				return this.$helpers.getObjectByKey(item, this.$attrs.itemText) || "";
			},
			getItemValue(item) {
				return this.$helpers.getObjectByKey(item, this.$attrs.itemValue) || "";
			},
			copyItemValue(item) {
				this.$helpers.copyText(this.getItemValue(item));
				this.showNotification("Short Code Copied!", "success");
			}
		}
	};
</script>
<style lang="scss">
	.is-long-list {
		max-height: 330px;
	}
	.is-small-list {
		max-height: 130px;
	}
	.copy-list-container {
		height: 100%;
		overflow: auto;
		padding: 20px !important;
	}
</style>