import helpers from "./helpers";

export const minLen =
  (l, msg = null) =>
  (v) =>
    v == null || (v && v.length >= l) || (msg ? msg : `min. ${l} Characters.`);

export const maxLen =
  (l, msg = null) =>
  (v) =>
    v == null || (v && v.length <= l) || (msg ? msg : `max. ${l} Characters.`);

export const minNumber =
  (l, msg = null) =>
  (v) =>
    v == null ||
    (v && Number.parseFloat(v) >= l) ||
    (msg ? msg : `min. ${l} Number.`);

export const maxNumber =
  (l, msg = null) =>
  (v) =>
    v == null ||
    (v && Number.parseFloat(helpers.parseNumbersFromString(v)) <= l) ||
    (msg ? msg : `max. ${l} Number.`);

export const notInInvalidValues =
  (invalidValues = [null, "", " "], msg = "Value is not valid.") =>
  (v) =>
    v == null || (v && !invalidValues.includes(v)) || msg;

export const validEmail =
  (msg = null) =>
  (v) =>
    v == null || (v && /.+@.+\..+/.test(v)) || (msg ? msg : `Invalid Email.`);

export const required =
  (msg = "Field is required.") =>
  (v) =>
    (!!v && v != null && v != "" && v != " ") || msg;

export const numberRequired =
  (msg = "Number is required.") =>
  (v) =>
    /^[0-9]*$/.test(v) || msg;

export const onlyNumbers =
  (msg = "Only Numbers are Valid.") =>
  (v) =>
    /^[0-9]*$/.test(v) || (v && v.includes(",")) || msg;

export const validUsername = (msg) => (v) =>
  /^[a-zA-Z0-9_]{5,}[a-zA-Z]+[0-9]*$/.test(v) || msg;

export const validUsername1 = (msg) => (v) =>
  /^(?=[a-z_\d]*[a-z])[a-z_\d]{6,}$/.test(v) || msg;

export const validUsername2 = (msg) => (v) =>
  /^[a-zA-Z0-9][a-zA-Z0-9_]*[a-zA-Z0-9](?<![-?\d+\.?\d*$]{6,}.*)$/.test(v) ||
  msg;

export const validUrl =
  (msg = "Not a valid url.") =>
  (v) =>
    [null].includes(v) ||
    /^(?:(?:(?:https?|http):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      v
    ) ||
    msg;

export const validDomainName =
  (msg = "Not a valid domain name.") =>
  (v) =>
    [null].includes(v) ||
    /\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/i.test(
      v
    ) ||
    msg;

export const validAlpha =
  (msg = "Invalid Alpha.") =>
  (v) =>
    /^[a-zA-Z]*$/.test(v) || msg;

export const validAlphaNum =
  (msg = "Invalid AlphaNum.") =>
  (v) =>
    /^[a-zA-Z0-9]*$/.test(v) || msg;

export const lessThenEqual = (val, msg) => (v) =>
  (v &&
    val &&
    helpers.getNumbersFromString(v) <= helpers.getNumbersFromString(val)) ||
  msg;

export const greaterThenEqual = (val, msg) => (v) =>
  (v &&
    val &&
    helpers.getNumbersFromString(v) >= helpers.getNumbersFromString(val)) ||
  msg;
