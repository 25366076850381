var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":"","single-line":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mt-3",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.getApplicationQuotes()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('createApplicationCustomQuoteDialog',{attrs:{"application":_vm.application},on:{"refresh":function($event){return _vm.getApplicationQuotes()}}}),_c('v-btn',{staticClass:"mt-3",attrs:{"icon":"","title":_vm.filters.hide_0_quotes
          ? 'Click To show 0 quotes'
          : 'Click To hide 0 quotes',"color":_vm.filters.hide_0_quotes ? "red" : "green"},on:{"click":function($event){_vm.filters.hide_0_quotes
          ? (_vm.filters.hide_0_quotes = false)
          : (_vm.filters.hide_0_quotes = true)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.filters.hide_0_quotes ? "mdi-eye-off" : "mdi-eye"))])],1),(
        _vm.application &&
        _vm.application.payload &&
        _vm.application.payload.status == 'complete'
      )?_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.sendDownloadApplicationQuoteDialogShow(
          _vm.application,
          null,
          'send-email'
        )}}},[_c('v-icon',[_vm._v("mdi-email-arrow-right-outline")])],1):_vm._e(),(
        _vm.application &&
        _vm.application.payload &&
        _vm.application.payload.status == 'complete'
      )?_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.sendDownloadApplicationQuoteDialogShow(_vm.application, null, 'send-sms')}}},[_c('v-icon',[_vm._v("mdi-cellphone-message")])],1):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"sort-by":['payload.premium'],"sort-desc":[false],"headers":_vm.headers,"items":_vm.quotes_filtered,"search":_vm.search,"items-per-page":-1,"footer-props":{
        itemsPerPageOptions: [5, 10, 20, -1],
      }},scopedSlots:_vm._u([{key:"item.payload.carrier_name",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-capitalize",staticStyle:{"min-width":"150px"}},[(
              _vm.getSelectedQuoteCarrierLink(item, item.payload.carrier_name)
            )?_c('a',{staticStyle:{"color":"black !important"},attrs:{"href":_vm.getSelectedQuoteCarrierLink(item, item.payload.carrier_name),"target":"_blank","rel":"noopener noreferrer"},on:{"click":function($event){return _vm.getOnClickEventForItem(item, item.payload.carrier_name)}}},[_vm._v(" "+_vm._s(_vm.selectedQuoteCarrierNameForUser(item))+" ")]):[_c('div',{on:{"click":function($event){return _vm.getOnClickEventForItem(item, item.payload.carrier_name)}}},[_vm._v(" "+_vm._s(_vm.selectedQuoteCarrierNameForUser(item))+" ")])]],2)]}},{key:"item.payload.description",fn:function(ref){
            var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getQuoteDescription(item))}})]}},{key:"item.payload.premium",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"150px"}},[_vm._v(" "+_vm._s(item.payload.premium ? ("$" + (Number.parseInt( item.payload.premium.toString().replace(/[^0-9\.]/g, "") ) .toString() .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"))) : "$0")+" ")])]}},{key:"item.updated_at",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-capitalize",staticStyle:{"min-width":"150px"}},[_vm._v(" "+_vm._s(item.updated_at)+" ")])]}},{key:"item.carrier_quote_number",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-capitalize",staticStyle:{"min-width":"80px","cursor":"pointer"},on:{"click":function($event){!!item.carrier_quote_number
              ? _vm.$helpers.copyText(item.carrier_quote_number)
              : function () {}}}},[(!!item.carrier_quote_number)?_c('v-icon',{attrs:{"small":"","dense":""}},[_vm._v("mdi-content-copy")]):_vm._e(),_vm._v(" "+_vm._s(item.carrier_quote_number)+" ")],1)]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-btn',{attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.sendDownloadApplicationQuoteDialogShow(
                _vm.application,
                item,
                'download'
              )}}},[_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.sendDownloadApplicationQuoteDialogShow(
                _vm.application,
                item,
                'send-email'
              )}}},[_c('v-icon',[_vm._v("mdi-email-arrow-right-outline")])],1),_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.sendDownloadApplicationQuoteDialogShow(
                _vm.application,
                item,
                'send-sms'
              )}}},[_c('v-icon',[_vm._v("mdi-cellphone-message")])],1)],1)]}}])})],1),(_vm.sendDownloadApplicationQuoteDialog.dialogModel)?[_c('sendDownloadApplicationQuoteDialog',_vm._b({on:{"closeDialog":function($event){return _vm.sendDownloadApplicationQuoteDialogClose()}}},'sendDownloadApplicationQuoteDialog',_vm.sendDownloadApplicationQuoteDialog,false))]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }