<template>
  <DialogBase
    :dialogWidth="1100"
    :dialogModel="dialogModel"
    @closeDialog="(val) => closeDialog(val)"
    scrollable
  >
    <template v-slot:title>
      <p class="text-capitalize">
        Application
        {{
          currentSelectedApplication &&
          currentSelectedApplication.application_id
            ? currentSelectedApplication.application_id
            : ""
        }}
        {{ formType.replace(/(-)/g, " ") }} Quote
      </p>
    </template>
    <v-card-actions class="subtitle-1 px-6">
      <v-row>
        <v-col v-for="(type, index) in formTypeList" :key="index">
          <v-btn
            color="secondary"
            block
            :outlined="formType != type"
            @click="formType = type"
          >
            {{ type.replace(/(-)/g, " ") }}
          </v-btn>
        </v-col>
        <v-col cols="1">
          <v-btn color="secondary" block @click="setFormDefaults()">
            Reset
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>

    <v-card-actions
      class="subtitle-1 px-4 text-capitalize"
      :class="'primary--text'"
      v-if="['flood', 'home'].includes(selectedApplication.application_type)"
    >
      <FormMaker
        :schema="pdf_template_type_structure"
        :model="form_data"
      ></FormMaker>
    </v-card-actions>

    <template v-for="(relatedApplication, index) in related_applications">
      <v-card-actions
        class="subtitle-1 px-6 text-capitalize"
        :class="relatedApplication.is_cross_sale ? '' : 'primary--text'"
        :key="index"
      >
        <template v-if="relatedApplication">
          <v-btn
            @click="
              addOrRemoveApplicationToSendableQuoteApplicationsList(
                relatedApplication
              )
            "
            color="secondary"
            small
            dense
            icon
          >
            <v-icon small>
              {{
                sendableQuoteApplicationIds.includes(relatedApplication.id)
                  ? `mdi-checkbox-outline`
                  : `mdi-checkbox-blank-outline`
              }}
            </v-icon>
          </v-btn>
          Type : {{ relatedApplication.application_type }}
          <v-spacer></v-spacer>
          <template
            v-if="
              selectedQuote &&
              relatedApplication.application_type ==
                selectedApplication.application_type
            "
          >
            Carrier : {{ selectedQuoteCarrierNameForUser(selectedQuote) }}
            <v-spacer></v-spacer>
            Premium : ${{ selectedQuote.payload.premium }}
          </template>
          <v-spacer></v-spacer>
          Min. Premium: ${{ relatedApplication.min_quote_amount || 0 }}
        </template>
      </v-card-actions>
    </template>

    <template v-if="showForm">
      <v-form id="form" @submit.prevent v-model="isValidForm" ref="from">
        <v-card-text>
          <FormMaker :schema="form_structure" :model="form_data"></FormMaker>
        </v-card-text>
        <v-card
          v-if="selectedApplicationUseableDetails"
          class="transparent pa-0 ma-0"
          elevation="0"
        >
          <v-card-title class="transparent-2 mb-4">
            Edit Pdf
            <v-spacer></v-spacer>
            <v-btn
              @click="showPdfEditForm = !!!showPdfEditForm"
              color="primary"
              icon
              outlined
            >
              <v-icon>
                {{ showPdfEditForm ? `mdi-menu-up` : `mdi-menu-down` }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text v-if="showPdfEditForm">
            <FormMaker
              :schema="pdf_form_structure"
              :model="form_data"
            ></FormMaker>
          </v-card-text>
        </v-card>
      </v-form>
    </template>

    <template v-slot:footer>
      <v-btn color="primary" class="text-capitalize" block @click="submit()">
        {{ formType.replace(/(-)/g, " ") }}
      </v-btn>
    </template>
  </DialogBase>
</template>

<script>
import {
  required,
  minLen,
  validEmail,
  maxNumber,
  lessThenEqual,
} from "@/plugins/validationRules.js";
import FormMaker from "@/components/FormMaker";
import DialogBase from "@/components/dialogs/Base";
import VueTextEditor from "@/components/special/VueTextEditor";
export default {
  components: { DialogBase, FormMaker, VueTextEditor },
  props: {
    dialogModel: { required: true, type: Boolean },
    selectedModel: { required: false, default: null },
    formTypeProp: { required: true, default: "send-email" },
    selectedApplication: { required: true, default: null },
    selectedQuote: { required: false, default: null },
  },
  async mounted() {
    this.formType = this.formTypeProp;
    await this.setFormDefaults();
    await this.getSystemShortCodes();
    await this.getSystemTemplates();
    await this.getAgentTemplates();
    this.showForm = true;
  },
  data() {
    return {
      isValidForm: false,
      showForm: false,
      currentSelectedApplication: null,
      selectedApplicationUseableDetails: null,
      showPdfEditForm: false,
      formType: "send-sms",
      formTypeList: ["send-sms", "send-email", "download"],
      sendableQuoteApplicationIds: [],
      agentTemplates: [],
      systemTemplates: [],
      systemShortCodes: [],
      form_data: {
        quote_amount: null,
        file_type: "pdf",
        pdf_template_type: null,
        selected_template: null,
        selected_template_option: null,
        option_body: null,
        option_subject: null,
        receiver_phone_number: null,
        send_application_pdf_as_media: "Yes",
        media_url: null,
        receiver_email: null,
        pdf_values: {
          quote_amount: null,
          effective_date: null,
          building_limit: null,
          contents_limit: null,
          garage_attached: null,
          replacement_cost_on_content: null,
          temporary_living_expense: null,
          deductible: null,
          dwelling: null,
          other_structure: null,
          loss_of_use: null,
          medical_payments: null,
          liability: null,
          aop_deductible: null,
          hurricane_deductible: null,
        },
      },
    };
  },
  computed: {
    related_applications() {
      return [];
      if (
        this.selectedApplication.application_type == "flood" &&
        this.selectedApplication.agent.assigned_product_type == "flood"
      ) {
        return [];
      }

      let relatedApplications = this.selectedApplication?.related_applications
        ?.filter((relatedApplication) => {
          if (
            ![this.currentSelectedApplication?.id].includes(
              relatedApplication?.related_application?.id
            ) &&
            [null, 0, "0", ""].includes(
              relatedApplication?.related_application?.min_quote_amount
            )
          ) {
            return false;
          }
          return true;
        })
        ?.map((relatedApplication) => {
          return {
            ...relatedApplication.related_application,
          };
        });

      if (relatedApplications.length > 0) {
        return relatedApplications;
      }

      if (this.currentSelectedApplication) {
        return [this.currentSelectedApplication];
      }

      if (this.selectedApplication) {
        return [this.selectedApplication];
      }

      return null;
    },
    form_structure() {
      var structure = {
        // file_type: {
        //   type: "btn-toggle",
        //   name: "file_type",
        //   label: "File Type",
        //   ...this.$helpers.formFieldsBasicLayout,
        //   options: ["pdf", "image"],
        //   mandatory: true,
        //   rules: [required],
        //   hidden: this.formType != "download",
        // },
        receiver_phone_number: {
          type: "text",
          name: "receiver_phone_number",
          label: "Receiver Phone Number",
          ...this.$helpers.formFieldsBasicLayout,
          mask: "(###) ###-####",
          hidden: !["send-sms"].includes(this.formType),
          rules: [required(), minLen(14, "10 Digits required.")],
        },
        receiver_email: {
          type: "text",
          name: "receiver_email",
          label: "Receiver Email",
          ...this.$helpers.formFieldsBasicLayout,
          hidden: !["send-email"].includes(this.formType),
          rules: [required(), validEmail()],
        },
        send_application_pdf_as_media: {
          type: "btn-toggle",
          name: "send_application_pdf_as_media",
          label: "Send Application Quote Pdf As Media",
          // ...this.$helpers.formFieldsBasicLayout,
          options: ["Yes", "No"],
          activeClass: "secondary white--text",
          class: "custom-style py-0 my-0 mb-2 px-2",
          mandatory: true,
          dense: true,
          col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
          hidden: this.formType != "send-sms",
          rules: [required],
        },
        media_url: {
          type: "text",
          name: "media_url",
          label: "Media Url",
          ...this.$helpers.formFieldsBasicLayout,
          col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
          hidden:
            this.formType != "send-sms" ||
            this.form_data.send_application_pdf_as_media == "Yes",
          rules: [],
        },
        selected_template: {
          type: "select",
          name: "selected_template",
          label: "Template Categories",
          ...this.$helpers.formFieldsBasicLayout,
          items: Object.keys(this.all_templates),
          returnObject: false,
          clearable: false,
          rules: [required()],
          hidden:
            ["download"].includes(this.formType) || !!this.selected_template,
        },
        selected_template_option: {
          type: "select",
          name: "selected_template_option",
          label: "Template",
          ...this.$helpers.formFieldsBasicLayout,
          items: this.selected_template?.payload?.body_options,
          itemText: "name",
          itemValue: "id",
          returnObject: false,
          clearable: false,
          rules: [required()],
          hidden: ["download"].includes(this.formType),
        },
        option_subject: {
          type: "text",
          name: "option_subject",
          label: "Email Subject",
          ...this.$helpers.formFieldsBasicLayout,
          rules: [required()],
          hidden:
            ["download"].includes(this.formType) ||
            this.selected_template?.payload?.body_type == "text",
        },
        option_body: {
          type:
            this.selected_template?.payload?.body_type == "html"
              ? "text-editor"
              : "textarea",
          name: "option_body",
          label: "Email Body",
          ...this.$helpers.formFieldsBasicLayout,
          rules: [required()],
          hidden: ["download"].includes(this.formType),
          col: { cols: 12, sm: 12, md: 7, lg: 8, xl: 8 },
        },
        short_codes: {
          type: "click-to-copy-list",
          name: "short_codes",
          label: "Short codes",
          ...this.$helpers.formFieldsBasicLayout,
          items: this.systemShortCodes.map((code) => {
            return {
              text: code.payload.name,
              value: `{{${code.payload.name}}}`,
              onclickPreview: `${code.payload.body}`,
            };
          }),
          itemText: "value",
          itemValue: "value",
          isLongList: this.selected_template?.payload?.body_type == "html",
          rules: [],
          hidden: ["download"].includes(this.formType),
          col: { cols: 12, sm: 12, md: 5, lg: 4, xl: 4 },
        },
      };

      return structure;
    },
    pdf_form_structure() {
      var structure = {
        pdf_values: {
          quote_amount: {
            type: "number",
            name: "quote_amount",
            label: "Quote Amount",
            ...this.$helpers.formFieldsBasicLayout,
            prefix: "$",
            rules: [required()],
          },
          effective_date: {
            type: "date",
            ext: "text",
            typeInt: "date",
            name: "effective_date",
            label: "Effective Date",
            ...this.$helpers.formFieldsBasicLayout,
            noTitle: true,
            appendIcon: "mdi-calendar-month-outline",
            newDateFormate: this.globalDateFormate,
            min: this.$moment().format("YYYY-MM-DD"),
            max: this.$moment().add(1, "months").format("YYYY-MM-DD"),
            menu: {
              closeOnContentClick: false,
              nudgeRight: 200,
              maxWidth: "290px",
              minWidth: "290px",
            },
            rules: [required()],
          },
          building_limit: {
            type: "text-currency",
            name: "building_limit",
            label: "Dwelling Limit",
            ...this.$helpers.formFieldsBasicLayout,
            prefix: "$",
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [],
            hidden:
              this.selectedApplicationUseableDetails.application_type !=
              "flood",
          },
          contents_limit: {
            type: "text-currency",
            name: "contents_limit",
            label: "Contents Limit",
            ...this.$helpers.formFieldsBasicLayout,
            prefix: "$",
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [],
            hidden:
              this.selectedApplicationUseableDetails.application_type !=
              "flood",
          },
          garage_attached: {
            type: "btn-toggle",
            name: "garage_attached",
            label: "Garage Attached?",
            ...this.$helpers.formFieldsBasicLayout,
            options: ["Yes", "No"],
            mandatory: true,
            class: "custom-style py-0 my-0 px-2 mb-1",
            rules: [required],
            hidden:
              this.selectedApplicationUseableDetails.application_type !=
              "flood",
          },
          replacement_cost_on_content: {
            type: "btn-toggle",
            name: "replacement_cost_on_content",
            label: "Replacement Cost On Contents?",
            ...this.$helpers.formFieldsBasicLayout,
            options: ["Yes", "No"],
            mandatory: true,
            class: "custom-style py-0 my-0 px-2 mb-1",
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required],
            hidden:
              this.selectedApplicationUseableDetails.application_type !=
              "flood",
          },
          temporary_living_expense: {
            type: "btn-toggle",
            name: "temporary_living_expense",
            label: "Temporary Living Expense?",
            ...this.$helpers.formFieldsBasicLayout,
            options: ["Yes", "No"],
            mandatory: true,
            class: "custom-style py-0 my-0 px-2 mb-1",
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required],
            hidden:
              this.selectedApplicationUseableDetails.application_type !=
              "flood",
          },
          deductible: {
            type: "btn-toggle",
            name: "deductible",
            label: "Deductible",
            ...this.$helpers.formFieldsBasicLayout,
            options: [
              "$1,000",
              // "$1,250",
              "$2,000",
              "$5,000",
              "$10,000",
              "$25,000",
            ],
            mandatory: true,
            class: "custom-style py-0 my-0 px-2 mb-1",
            rules: [required],
            hidden:
              this.selectedApplicationUseableDetails.application_type !=
              "flood",
          },
          dwelling: {
            type: "text-currency",
            name: "dwelling",
            label: "Dwelling",
            ...this.$helpers.formFieldsBasicLayout,
            prefix: "$",
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required(), maxNumber(10000000)],
            hidden:
              this.selectedApplicationUseableDetails.application_type != "home",
          },
          contents: {
            type: "text-currency",
            name: "contents",
            label: "Contents",
            ...this.$helpers.formFieldsBasicLayout,
            prefix: "$",
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required(), maxNumber(10000000)],
            hidden:
              this.selectedApplicationUseableDetails.application_type != "home",
          },
          other_structure: {
            type: "text-currency",
            name: "other_structure",
            label: "Other Structure",
            ...this.$helpers.formFieldsBasicLayout,
            prefix: "$",
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
            hidden:
              this.selectedApplicationUseableDetails.application_type != "home",
          },
          loss_of_use: {
            type: "text-currency",
            name: "loss_of_use",
            label: "Loss Of Use",
            ...this.$helpers.formFieldsBasicLayout,
            prefix: "$",
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
            hidden:
              this.selectedApplicationUseableDetails.application_type != "home",
          },
          medical_payments: {
            type: "select",
            name: "medical_payments",
            label: "Medical Payments",
            ...this.$helpers.formFieldsBasicLayout,
            items: ["$0", "$1,000", "$2,000", "$3,000", "$4,000", "$5,000"],
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
            hidden:
              this.selectedApplicationUseableDetails.application_type != "home",
          },
          liability: {
            type: "select",
            name: "liability",
            label: "Liability",
            ...this.$helpers.formFieldsBasicLayout,
            items: [
              "$0",
              "$100,000",
              "$200,000",
              "$300,000",
              "$500,000",
              "$1,000,000",
            ],
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
            hidden:
              this.selectedApplicationUseableDetails.application_type != "home",
          },
          aop_deductible: {
            type: "btn-toggle",
            name: "aop_deductible",
            label: "AOP Deductible",
            ...this.$helpers.formFieldsBasicLayout,
            options: ["$500", "$1,000", "$2,500", "$5,000", "$10,000"],
            mandatory: true,
            class: "custom-style py-0 my-0 px-2 mb-1",
            rules: [required],
            hidden:
              this.selectedApplicationUseableDetails.application_type != "home",
          },
          hurricane_deductible: {
            type: "btn-toggle",
            name: "hurricane_deductible",
            label: "Hurricane Deductible",
            ...this.$helpers.formFieldsBasicLayout,
            options: ["$1,000", "$2,500", "2%", "5%"],
            mandatory: true,
            class: "custom-style py-0 my-0 px-2 mb-1",
            rules: [required],
            hidden:
              this.selectedApplicationUseableDetails.application_type != "home",
          },
        },
      };

      return structure;
    },
    pdf_template_type_structure() {
      var structure = {
        pdf_template_type: {
          type: "btn-toggle",
          name: "pdf_template_type",
          label: "PDF Type?",
          ...this.$helpers.formFieldsBasicLayout,
          options: ["Multi Quote PDF", "Single Quote PDF"],
          mandatory: true,
          class: "custom-style py-0 my-0 px-2 mb-1",
          rules: [required],
        },
      };
      return structure;
    },
    all_templates() {
      let allTemplates = {};

      this.systemTemplates.forEach((template) => {
        allTemplates[template.payload.name] = template;
      });

      this.agentTemplates.forEach((template) => {
        allTemplates[template.payload.name] = template;
      });

      return allTemplates;
    },
    default_template_name() {
      let templateFor = this.formType == "send-sms" ? "sms" : "email";

      if (this.sendableQuoteApplicationIds.length > 1) {
        return templateFor == "sms"
          ? "Multi - Quote - Sms"
          : "Multi - Quote - Email";
      }

      return this.currentSelectedApplication?.default_template_names[
        templateFor
      ];
    },
    default_template() {
      return this.all_templates[this.default_template_name];
    },
    default_template_option() {
      let defaultTemplateOptions = this.default_template?.payload?.body_options;
      let defaultTemplateSelectedOption = null;

      if (defaultTemplateOptions) {
        defaultTemplateSelectedOption = defaultTemplateOptions.find(
          (opt) => opt.is_selected == "Yes"
        );
      }

      return defaultTemplateSelectedOption;
    },
    selected_template_name() {
      return this.form_data?.selected_template;
    },
    selected_template() {
      return this.all_templates[this.selected_template_name];
    },
    selected_template_option() {
      let selectedTemplateOptionId = this.form_data?.selected_template_option;

      let selectedTemplateOption = null;

      if (selectedTemplateOptionId)
        selectedTemplateOption =
          this.selected_template?.payload?.body_options?.find((bodyOption) => {
            // console.log(bodyOption);
            return bodyOption.id == selectedTemplateOptionId;
          });

      return selectedTemplateOption;
    },
  },
  watch: {
    formType(newVal, oldVal) {
      if (newVal != oldVal) this.setTemplate();
    },
    "form_data.selected_template"(newVal, oldVal) {
      if (newVal != oldVal)
        this.setTemplate(this.selected_template, this.selected_template_option);
    },
    "form_data.selected_template_option"(newVal, oldVal) {
      if (newVal != oldVal)
        this.setTemplate(this.selected_template, this.selected_template_option);
    },
    "form_data.quote_amount"(newVal, oldVal) {
      if (newVal != oldVal) this.form_data.pdf_values.quote_amount = newVal;
    },
    "form_data.pdf_values.quote_amount"(newVal, oldVal) {
      if (newVal != oldVal) this.form_data.quote_amount = newVal;
    },
    sendableQuoteApplicationIds(newVal, oldVal) {
      this.form_data.selected_template = this.default_template_name;
    },
  },
  methods: {
    closeDialog(val = false) {
      this.$emit("closeDialog", val);
    },
    addOrRemoveApplicationToSendableQuoteApplicationsList(application) {
      if (
        application.application_type ==
        this.selectedApplication.application_type
      ) {
        return null;
      }

      if (this.sendableQuoteApplicationIds.includes(application.id)) {
        return this.removeIdFromSendableQuoteApplicationIds(application);
      }

      return this.addIdFromSendableQuoteApplicationIds(application);
    },
    addIdFromSendableQuoteApplicationIds(application) {
      this.sendableQuoteApplicationIds.push(application.id);
    },
    removeIdFromSendableQuoteApplicationIds(application) {
      this.sendableQuoteApplicationIds =
        this.sendableQuoteApplicationIds.filter((id) => id != application.id);
    },
    setTemplate(template = null, templateOption = null) {
      let selectedTemplate = template || this.default_template;

      let selectedTemplateOption =
        templateOption || this.default_template_option;

      let selectedOptionId = selectedTemplateOption?.id;

      this.form_data["selected_template"] = selectedTemplate?.payload?.name;
      this.form_data["selected_template_option"] = selectedOptionId;
      this.form_data["option_body"] = selectedTemplateOption?.body;
      this.form_data["option_subject"] = selectedTemplateOption?.subject;
    },
    async setFormDefaults() {
      if (this.selectedApplication) {
        this.currentSelectedApplication = Object.assign(
          {},
          this.selectedApplication
        );
        this.addIdFromSendableQuoteApplicationIds(this.selectedApplication);
      }
      if (this.currentSelectedApplication?.payload?.useable_details) {
        this.selectedApplicationUseableDetails = Object.assign(
          {},
          this.currentSelectedApplication?.payload?.useable_details
        );
      }

      await this.$nextTick();

      if (this.currentSelectedApplication) {
        let usableDetails = this.selectedApplicationUseableDetails;

        if (
          this.selectedApplication?.agent?.assigned_product_type == "flood" &&
          this.selectedApplication?.application_type == "flood"
        ) {
          this.form_data["pdf_template_type"] = "Single Quote PDF";
        } else {
          this.form_data["pdf_template_type"] = "Multi Quote PDF";
        }

        if (usableDetails) {
          this.form_data["pdf_values"] = usableDetails;
          this.form_data["pdf_values"]["garage_attached"] =
            usableDetails["garage_attached"] || "No";
          this.form_data["pdf_values"]["replacement_cost_on_content"] =
            usableDetails["replacement_cost_on_content"] || "No";
          this.form_data["pdf_values"]["temporary_living_expense"] =
            usableDetails["temporary_living_expense"] || "No";
        }

        this.form_data["quote_amount"] =
          this.selectedQuote?.payload?.premium ||
          this.currentSelectedApplication?.min_quote_amount;

        this.form_data["receiver_phone_number"] =
          this.currentSelectedApplication.phone;

        this.form_data["receiver_email"] =
          this.currentSelectedApplication.email;

        this.setTemplate();
      }
    },
    async getSystemShortCodes() {
      let data = {
        agent_id: this.currentUser?.agent?.id,
      };

      // console.log(this.user?.agent?.id);

      return await this.post(
        `${this.baseUrl}/agent-portal/get-system-short-codes`,
        data,
        true
      ).then((data) => {
        if (data == "undefined") return;
        // this.showMessageFromResponseDta(data);
        if (data.code == 200) {
          this.systemShortCodes = data.data.system_short_codes;
        }
      });
    },
    async getAgentTemplates() {
      if (!this.selectedApplication?.agent_id) return;

      let data = {
        agent_id: this.selectedApplication?.agent_id,
      };

      return await this.post(
        `${this.baseUrl}/agent-portal/get-agent-templates`,
        data,
        true
      ).then((data) => {
        if (data == "undefined") return;
        // this.showMessageFromResponseDta(data);
        if (data.code == 200) {
          this.agentTemplates = data.data.agent_templates;
          this.setTemplate();
        }
      });
    },
    async getSystemTemplates() {
      let data = {
        agent_id: this.currentUser?.agent?.id,
      };

      // console.log(this.currentUser);

      return await this.post(
        `${this.baseUrl}/agent-portal/get-system-templates`,
        data,
        true
      ).then((data) => {
        if (data == "undefined") return;
        // this.showMessageFromResponseDta(data);
        if (data.code == 200) {
          this.systemTemplates = data.data.system_templates;
          this.setTemplate();
        }
      });
    },
    async submit() {
      this.$refs.from.validate();
      if (!this.isValidForm) return;

      let endpointUrl = null;
      let conformationMessageText = null;
      let callBackFunction = null;

      if (this.formType == "send-sms") {
        endpointUrl = "agent-portal/send-or-download-quote";
        conformationMessageText = `Are you sure you want to send quote to number: "${this.form_data.receiver_phone_number}"`;
      }
      if (this.formType == "send-email") {
        endpointUrl = "agent-portal/send-or-download-quote";
        conformationMessageText = `Are you sure you want to send quote to email. "${this.form_data.receiver_email}"`;
      }
      if (this.formType == "download") {
        endpointUrl = "agent-portal/send-or-download-quote";
        conformationMessageText = null;
        callBackFunction = (data) => {
          if (data.data && data.data.pdf_file_url) {
            this.$helpers.downloadFromUrl(data.data.pdf_file_url);
          }
        };
      }

      if (conformationMessageText && !confirm(conformationMessageText)) {
        return false;
      }

      let formData = this.form_data;

      formData["quote_amount"] = formData.pdf_values.quote_amount;
      formData["pdf_values"]["building_limit"] =
        formData.pdf_values.building_limit || 0;
      formData["pdf_values"]["contents_limit"] =
        formData.pdf_values.contents_limit || 0;

      let data = {
        request_type: this.formType,
        sendable_quote_application_ids: this.sendableQuoteApplicationIds,
        application_id: this.currentSelectedApplication?.id,
        quote_id: this.selectedQuote?.id,
        ...formData,
      };

      return await this.post(`${this.baseUrl}/${endpointUrl}`, data, true).then(
        (data) => {
          if (data) {
            this.showMessageFromResponseDta(data);
            if (callBackFunction) callBackFunction(data);
          }
          if (data?.code == 200) this.closeDialog();
          return data;
        }
      );
    },
  },
};
</script>
