/* eslint-disable prettier/prettier */
import store from "../store";

class Auth {
  constructor() {
    this.session_key = null;
    this.user = null;
    this.user_role = null;
    this.previous_user_data = null;
  }

  initAuth() {
    if (this.isLogin()) {
      this.session_key = window.localStorage.getItem("session_key");
      this.user = window.localStorage.getItem("user");
      this.user_role = window.localStorage.getItem("user_role");
      this.login(this.session_key, this.user, this.user_role);
      return;
    }
    this.logout();
    return;
  }

  login(session_key, user, user_role) {
    this.session_key = session_key;
    this.user = user;
    this.user_role = user_role;

    if (!this.isLogin()) {
      // if (user.profile_image == null)
      //     this.user.profile_image = "/profile_images/profile-placeholder.png";

      window.localStorage.setItem("session_key", this.session_key);
      window.localStorage.setItem("user", JSON.stringify(this.user));
      window.localStorage.setItem("user_role", this.user_role);
    }

    store.dispatch("auth/login", {
      session_key: this.session_key,
      user: this.user,
      user_role: this.user_role,
    });

    this.initPreviousUserData();
  }

  logout() {
    this.session_key = null;
    this.user_role = null;
    this.user = null;
    window.localStorage.setItem("session_key", null);
    window.localStorage.setItem("user", null);
    window.localStorage.setItem("user_role", null);
    store.dispatch("auth/logout");
  }

  isLogin() {
    if (
      window.localStorage.getItem("session_key") === "undefined" ||
      window.localStorage.getItem("session_key") == "null" ||
      window.localStorage.getItem("session_key") == null
    ) {
      return false;
    }
    return true;
  }

  initPreviousUserData() {
    let previous_user_data =
      window.localStorage.getItem("previous_user_data") || null;

    if (previous_user_data) {
      previous_user_data = helpers.isJson(previous_user_data)
        ? JSON.parse(previous_user_data)
        : previous_user_data;

      this.previous_user_data = previous_user_data;

      this.setPreviousUserData(previous_user_data);
    }
  }

  saveCurrentUseAsPreviousUserData() {
    if (
      window.localStorage.getItem("session_key") === "undefined" ||
      window.localStorage.getItem("session_key") == "null" ||
      window.localStorage.getItem("session_key") == null
    ) {
      window.localStorage.setItem("previous_user_data", null);
      return false;
    }

    let user = window.localStorage.getItem("user");
    let session_key = window.localStorage.getItem("session_key");
    let user_role = window.localStorage.getItem("user_role");

    this.setPreviousUserData({ user, session_key, user_role });

    return true;
  }

  unsetPreviousUserData() {
    this.previous_user_data = null;
    window.localStorage.setItem("previous_user_data", null);
    store.commit("auth/set_previous_user_data", null);
    return true;
  }

  setPreviousUserData({ user, session_key, user_role }) {
    user = helpers.isJson(user) ? JSON.parse(user) : user;
    this.previous_user_data = {
      user,
      session_key,
      user_role,
    };

    window.localStorage.setItem(
      "previous_user_data",
      JSON.stringify(this.previous_user_data)
    );

    store.commit("auth/set_previous_user_data", this.previous_user_data);
  }
}

export default new Auth();
