<template></template>

<script>
	import http from "@/plugins/http";
	export default {
		data: () => {
			return {};
		},
		head: {
			title: function() {
				return {
					inner: "Logout"
				};
			}
		},
		computed: {},
		watch: {},
		created() {
			if (this.currentUser) this.logout();
			else this.$router.push({ name: "login" });
		},
		methods: {
			logout() {
				var $this = this;
				let data = {
					session_key: this.sessionKey
				};
				if (this.sessionKey)
					this.post($this.baseUrl + "/logout", data, true).then(data => {
						if (data == "undefined") return;
						this.showMessageFromResponseDta(data);

						auth.logout();
						$this.$router.push({ name: "login" });
					});
				else {
					auth.logout();
					$this.$router.push({ name: "login" });
				}
			}
		}
	};
</script>

<style lang="scss">
</style>
