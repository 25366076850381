import axios from 'axios';
import store from '@/store';

const http = axios.create({
    headers: {
        "Content-Type": "application/json;charset=utf-8;multipart/form-data",
        "Access-Control-Allow-Origin": "*",
    }
});

http.interceptors.request.use(config => {
    store.commit('setLoading', true);
    return config;
}, error => {
    store.commit('setLoading', false);
    return Promise.reject(error);
});

http.interceptors.response.use(response => {
    store.commit('setLoading', false);
    return response;
}, error => {
    store.commit('setLoading', false);
    return Promise.reject(error);
});



export default http;