<template> </template>

<script>
	export default {
		components: {},
		data: () => {
			return {};
		},
		head: {
			title: function() {
				return {
					inner: "Account"
				};
			}
		},
		computed: {},
		watch: {},
		mounted() {
			// console.log(this.currentUser);
			if (this.currentUser) {
				this.$router.replace({ name: "dashboard-home" });
			}
		},
		methods: {}
	};
</script>

<style lang="scss">
</style>
