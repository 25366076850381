<template>
	<v-app id="app">
		<!-- Loading progress bar -->
		<nprogress-container></nprogress-container>
		<!-- Loading -->
		<Loading></Loading>
		<SpecialLoading></SpecialLoading>
		<!-- pages -->
		<router-view />
		<notifications position="bottom center" group="msg" />
		<!-- <idleController/> -->
	</v-app>
</template>
<script>
	import NprogressContainer from "vue-nprogress/src/NprogressContainer";
	import Loading from "@/components/Loading";
	import SpecialLoading from "@/components/SpecialLoading";
	import idleController from "@/components/general/idleController";

	export default {
		components: { Loading, SpecialLoading, NprogressContainer, idleController },
		watch: {}
	};
</script>
