var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"agent-portal"}},[_c('v-app-bar',{staticClass:"header dark-primary-bg elevation-0",attrs:{"height":"120","absolute":"","app":""}},[_c('router-link',{attrs:{"to":{ name: 'dashboard-home' }}},[_c('div',{staticClass:"ml-12",class:['xs', 'sm'].includes(_vm.$vuetify.breakpoint.name) ? 'd-none' : '',staticStyle:{"max-width":"70px"}},[_c('v-img',{staticClass:"img",staticStyle:{"width":"100%"},attrs:{"src":"/img/favicon.png","contain":""}})],1)]),_c('v-spacer'),_c('router-link',{attrs:{"to":{
				name:
					_vm.currentUserRole == 'customer' ? 'dashboard-home' : 'agent-portal',
			}}},[_c('div',{staticStyle:{"max-width":"300px"}},[_c('v-img',{staticStyle:{"width":"100%"},attrs:{"src":"/img/logo2.png","contain":""}}),_c('div',{staticClass:"body-1 px-6 py-2 text-center"},[_vm._v(" The #1 InsureTech Agency Group in the Country ")])],1)]),_c('v-spacer'),_c('div',{staticClass:"mr-12",class:['xs', 'sm'].includes(_vm.$vuetify.breakpoint.name) ? 'd-none' : '',staticStyle:{"max-width":"70px"}},[_c('div',{staticStyle:{"width":"70px"}},[_vm._v(" ")])])],1),_c('v-main',{staticClass:"mt-0 mb-10 light-light-bg"},[_c('v-container',[_c('router-view')],1)],1),_c('v-footer',{attrs:{"absolute":"","padless":true,"app":""}},[_c('v-container',{staticClass:"footer dark-primary-bg pa-10",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"pb-2",staticStyle:{"width":"100%","max-width":"900px","border-bottom":"1px solid white"}},[_c('div',{staticStyle:{"max-width":"400px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"contain":"","src":"/img/logo2.png","alt":"logo"}})])])]),_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"mt-6",staticStyle:{"width":"100%","max-width":"900px"}},[_c('div',{staticClass:"d-flex align-stretch",class:['xs', 'sm'].includes(_vm.$vuetify.breakpoint.name)
								? 'flex-column'
								: 'flex-row'},[_c('div',{staticStyle:{"min-width":"33%"}},[_c('div',{staticClass:"body-1 font-weight-light text-capitalize pa-2"},[_vm._v(" The #1 InsureTech Agency Group in the Country. Help your clients secure the most competitive flood Insurance Obtainable. ")])]),_c('div',{staticStyle:{"min-width":"33%"}},[_c('div',{staticClass:"body-1 font-weight-light text-uppercase pa-2",class:!['xs', 'sm'].includes(_vm.$vuetify.breakpoint.name)
										? 'px-10'
										: ''},[_c('div',{staticClass:"light-primary-color font-weight-bold py-1"},[_vm._v(" Quick Links ")]),_c('div',{staticClass:"pb-1"},[_c('a',{attrs:{"href":"//quotes.rocketflood.com/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Home")])]),_c('div',{staticClass:"pb-1"},[_c('a',{attrs:{"href":"//quotes.rocketflood.com/about","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("About")])]),_c('div',{staticClass:"pb-1"},[_c('a',{attrs:{"href":"//quotes.rocketflood.com/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Get A Quote")])]),_c('div',{staticClass:"pb-1"},[_c('a',{attrs:{"href":"//quotes.rocketflood.com/about","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Contact")])])])]),_c('div',{staticStyle:{"min-width":"33%"}},[_c('div',{staticClass:"body-1 font-weight-light text-capitalize pa-2"},[_c('div',{staticClass:"light-primary-color font-weight-bold py-1 text-uppercase"},[_vm._v(" Address ")]),_c('div',{staticClass:"pb-1"},[_vm._v(" 2005 Vista Parkway, Suite 200 West Palm Beach, FL 33411 ")])]),_c('div',{staticClass:"body-1 font-weight-light text-uppercase pa-2"},[_c('div',{staticClass:"light-primary-color font-weight-bold py-1"},[_vm._v(" Phone number ")]),_c('div',{staticClass:"pb-1"},[_c('a',{attrs:{"href":"tel:561-404-4498"}},[_vm._v("561-404-4498")])])])])])])])]),_c('v-container',{staticClass:"footer-secondary dark-secondary-bg",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-center body-3 font-weight-light text-uppercase"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" ROCKETFLOOD.COM · All rights Reserved. · Privacy Policy ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }