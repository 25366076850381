<template>
  <div>
    <v-card-title>
      <v-text-field
        append-icon="mdi-magnify"
        v-model="search"
        label="Search"
        hide-details
        single-line
        clearable
      ></v-text-field>
      <v-btn
        icon
        class="mt-3"
        color="primary"
        @click="getApplicationNotesAndAttachments()"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <createApplicationNoteAndAttachmentBtn
        @refresh="getApplicationNotesAndAttachments()"
        :application="application"
      />
    </v-card-title>
    <v-card-text>
      <div class="d-flex flex-column" style="gap: 4px">
        <template
          v-for="(zoho_crm_module, index) in application.zoho_crm_modules"
        >
          <template v-if="index == 0">
            <div
              :key="index"
              class="d-flex flex-row items-center justify-between transparent-2 pa-1"
            >
              <div class="d-flex flex-grow-1">
                {{ zoho_crm_module.zoho_module_name }}
              </div>
              <div class="d-flex">
                {{ zoho_crm_module.zoho_module_instance_id }}
              </div>
            </div>
          </template>
        </template>
      </div>
      <v-data-table
        :sort-by="[]"
        :sort-desc="[false]"
        :headers="headers"
        :items="notes_and_attachments_filtered"
        :items-per-page="-1"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 20, -1],
        }"
      >
        <template v-slot:item.title="{ item }">
          <div
            class="cursor-pointer primary--text text-underline"
            v-if="isAttachment(item)"
            @click="downloadAttachment(item)"
          >
            {{ item.File_Name || "N/A" }}
          </div>
          <div v-else>
            {{ item.Note_Title || "N/A" }}
          </div>
        </template>
        <template v-slot:item.body="{ item }">
          <div>
            <pre>{{ item.Note_Content || "N/A" }}</pre>
          </div>
        </template>
        <template v-slot:item.attachment="{ item }">
          <div v-if="item.$attachments && item.$attachments.length">
            <template v-for="(attachment, index) in item.$attachments">
              <div
                class="cursor-pointer primary--text text-underline"
                @click="downloadAttachment(attachment)"
                :key="index"
              >
                {{ attachment.File_Name }}
              </div>
            </template>
          </div>
          <div v-else>N/A</div>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import { mapState } from "vuex";
import createApplicationNoteAndAttachmentBtn from "@/components/general/createApplicationNoteAndAttachmentBtn";
export default {
  components: { createApplicationNoteAndAttachmentBtn },
  props: {},
  data() {
    return {
      notesAndAttachments: [],
      search: null,
      headers: [
        {
          sortable: true,
          text: "Title",
          value: "title",
          align: "left",
        },
        {
          sortable: true,
          text: "Body",
          value: "body",
          align: "left",
        },
        {
          sortable: true,
          text: "Attachment",
          value: "attachment",
          align: "left",
        },
      ],
    };
  },
  mounted() {
    this.getApplicationNotesAndAttachments();
  },
  computed: {
    ...mapState({
      application: (state) => state.application.application,
    }),
    notes_and_attachments() {
      return (
        this.notesAndAttachments?.notes?.concat(
          this.notesAndAttachments?.attachments || []
        ) || []
      );
    },
    notes_and_attachments_filtered() {
      return this.notes_and_attachments?.filter((data) =>
        this.isSearchStringInData(this.search, data)
      );
    },
  },
  beforeDestroy() {},
  methods: {
    showCreateNoteAndAttachment(item) {
      return;
    },
    isAttachment(item) {
      return !!item?.File_Name;
    },
    getAttachmentData(item) {
      return {
        parent_module: item?.$se_module,
        parent_id: item?.Parent_Id?.id,
        attachment_id: item?.id,
      };
    },
    async downloadAttachment(item) {
      //   console.log(item);
      let data = {
        session_key: this.sessionKey,
        ...this.getAttachmentData(item),
      };
      return await this.post(
        `${this.baseUrl}/agent-portal/download-attachment-from-zoho`,
        data,
        true
      ).then((res) => {
        if (res) {
          this.showMessageFromResponseDta(res);
          if (res.code == 200) {
            this.$helpers.downloadFromUrl(res?.data?.file_url);
          }
        }
        return res;
      });
    },
    async getApplicationNotesAndAttachments() {
      if (!!!this.application?.id) return false;

      let data = {
        session_key: this.sessionKey,
        application_id: this.application.id,
      };

      return await this.post(
        `${this.baseUrl}/agent-portal/get-note-and-attachments-of-application`,
        data,
        true
      ).then((res) => {
        if (res) {
          this.showMessageFromResponseDta(res);

          if (res.code == 200) {
            this.notesAndAttachments = res.data;
          }
        }

        return res;
      });
    },
  },
};
</script>
