var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":"","single-line":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mt-3",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.getApplicationNotes()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"sort-by":[],"sort-desc":[false],"headers":_vm.headers,"items":_vm.notes_filtered,"search":_vm.search,"items-per-page":-1,"footer-props":{
        itemsPerPageOptions: [5, 10, 20, -1],
      }},scopedSlots:_vm._u([{key:"item.key",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.key.toString().replace(/_/g, " "))+" ")])]}},{key:"item.payload",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-capitalize"},[_vm._l((item.payload),function(dis,key){return [(!['is_alert', 'is_active'].includes(key))?_c('v-row',{key:key,attrs:{"no-gutters":""}},[(key == 'sent_at')?_c('v-col',[_vm._v(" "+_vm._s(key.toString().replace(/_/g, " "))+": "+_vm._s(item.created_at)+" ")]):_c('v-col',[_vm._v(" "+_vm._s(key ? key.toString().replace(/_/g, " ") : key)+": "+_vm._s(dis ? dis.toString().replace(/_/g, " ") : dis)+" ")])],1):_vm._e()]})],2)]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',[(item.payload.is_alert)?_c('v-btn',{attrs:{"color":item.payload.is_active ? 'error' : 'success',"icon":"","title":item.payload.is_active
                ? 'Click To Active'
                : 'Click To Deactivate'},on:{"click":function($event){return _vm.toggleApplicationAlertNote(item)}}},[_c('v-icon',[_vm._v(_vm._s(item.payload.is_active ? "mdi-bell-outline" : "mdi-bell-off-outline"))])],1):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }