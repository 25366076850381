<template>
  <div class="sms-module">
    <div class="side-bar">
      <div class="rectangle-4232"></div>

      <div class="frame-7884">
        <div class="contact">
          <div class="frame-7864">
            <div class="john-doe">John Doe</div>

            <div class="_12-00-pm">12:00 PM</div>
          </div>

          <div class="this-was-the-last-message-sent">
            This was the last message sent...
          </div>
        </div>

        <div class="contact2">
          <div class="frame-7864">
            <div class="john-doe">John Doe</div>

            <div class="_12-00-pm">12:00 PM</div>
          </div>

          <div class="this-was-the-last-message-sent">
            This was the last message sent...
          </div>
        </div>

        <div class="contact2">
          <div class="frame-7864">
            <div class="john-doe">John Doe</div>

            <div class="_12-00-pm">12:00 PM</div>
          </div>

          <div class="this-was-the-last-message-sent">
            This was the last message sent...
          </div>
        </div>

        <div class="contact2">
          <div class="frame-7864">
            <div class="john-doe">John Doe</div>

            <div class="_12-00-pm">12:00 PM</div>
          </div>

          <div class="this-was-the-last-message-sent">
            This was the last message sent...
          </div>
        </div>

        <div class="contact2">
          <div class="frame-7864">
            <div class="john-doe">John Doe</div>

            <div class="_12-00-pm">12:00 PM</div>
          </div>

          <div class="this-was-the-last-message-sent">
            This was the last message sent...
          </div>
        </div>

        <div class="contact2">
          <div class="frame-7864">
            <div class="john-doe">John Doe</div>

            <div class="_12-00-pm">12:00 PM</div>
          </div>

          <div class="this-was-the-last-message-sent">
            This was the last message sent...
          </div>
        </div>
      </div>

      <div class="all-contacts">All Contacts</div>

      <div class="frame-7818">
        <svg
          class="_24-px-gray-search"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.8571 9.42857C16.8571 5.32588 13.5313 2 9.42857 2C5.32588 2 2 5.32588 2 9.42857C2 13.5313 5.32588 16.8571 9.42857 16.8571C11.2686 16.8571 12.96 16.1829 14.2629 15.0743L14.5714 15.3829V16.2857L20.2857 22L22 20.2857L16.2857 14.5714H15.3829L15.0743 14.2629C16.1829 12.96 16.8571 11.2686 16.8571 9.42857ZM4.28571 9.42857C4.28571 6.57143 6.57143 4.28571 9.42857 4.28571C12.2857 4.28571 14.5714 6.57143 14.5714 9.42857C14.5714 12.2857 12.2857 14.5714 9.42857 14.5714C6.57143 14.5714 4.28571 12.2857 4.28571 9.42857Z"
            fill="#8595A1"
          />
        </svg>

        <div class="type-contact-name">Type contact name</div>
      </div>

      <div class="frame-7872">
        <svg
          class="_24-px-dark-add"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.5 12.4286C22.5 12.9809 22.0523 13.4286 21.5 13.4286H13.9286V21C13.9286 21.5523 13.4809 22 12.9286 22H12.0714C11.5191 22 11.0714 21.5523 11.0714 21V13.4286H3.5C2.94771 13.4286 2.5 12.9809 2.5 12.4286V11.5714C2.5 11.0191 2.94772 10.5714 3.5 10.5714H11.0714V3C11.0714 2.44771 11.5191 2 12.0714 2H12.9286C13.4809 2 13.9286 2.44772 13.9286 3V10.5714H21.5C22.0523 10.5714 22.5 11.0191 22.5 11.5714V12.4286Z"
            fill="white"
          />
        </svg>

        <div class="create-new-message">Create New Message</div>
      </div>

      <div class="frame-7883">
        <div class="frame-7882">
          <div class="frame-7880">
            <div class="ellipse-1"></div>

            <div class="cc">CC</div>
          </div>

          <div class="frame-7881">
            <div class="chris-rocha">Chris Rocha</div>

            <div class="rocket-flood">Rocket Flood</div>
          </div>
        </div>

        <svg
          class="uiw-edit"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_13_191)">
            <path
              d="M2.292 13.36L6.815 18.116L0.5 20L2.292 13.36ZM12.705 2.412L17.227 7.167L7.266 17.64L2.743 12.886L12.705 2.412ZM16.142 0.347999L19.118 3.477C19.925 4.325 19.204 5.09 19.204 5.09L17.683 6.69L13.159 1.933L14.68 0.333999L14.7 0.314999C14.819 0.202999 15.475 -0.353001 16.142 0.347999Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_13_191">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>

    <div class="frame-7873">
      <div class="john-doe2">John Doe</div>

      <div class="frame-7863">
        <div class="noun-hamburger-1194167-1">
          <svg
            class="group"
            width="21"
            height="14"
            viewBox="0 0 21 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.1513 2.70363H1.12729C0.504522 2.70363 0 2.09821 0 1.35261C0 0.605364 0.504571 0 1.12729 0H19.1513C19.7727 0 20.2773 0.605423 20.2773 1.35261C20.2773 2.09821 19.7727 2.70363 19.1513 2.70363Z"
              fill="#172340"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.1513 8.35117H1.12729C0.504522 8.35117 0 7.74574 0 7.00014C0 6.2529 0.504571 5.64753 1.12729 5.64753H19.1513C19.7727 5.64753 20.2773 6.25295 20.2773 7.00014C20.2773 7.74574 19.7727 8.35117 19.1513 8.35117Z"
              fill="#172340"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.1513 14.0001H1.12729C0.504522 14.0001 0 13.3947 0 12.6491C0 11.9018 0.504571 11.2965 1.12729 11.2965H19.1513C19.7727 11.2965 20.2773 11.9019 20.2773 12.6491C20.2773 13.3947 19.7727 14.0001 19.1513 14.0001Z"
              fill="#172340"
            />
          </svg>
        </div>
      </div>

      <div class="frame-78182">
        <svg
          class="_24-px-gray-search2"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.8571 9.42857C16.8571 5.32588 13.5313 2 9.42857 2C5.32588 2 2 5.32588 2 9.42857C2 13.5313 5.32588 16.8571 9.42857 16.8571C11.2686 16.8571 12.96 16.1829 14.2629 15.0743L14.5714 15.3829V16.2857L20.2857 22L22 20.2857L16.2857 14.5714H15.3829L15.0743 14.2629C16.1829 12.96 16.8571 11.2686 16.8571 9.42857ZM4.28571 9.42857C4.28571 6.57143 6.57143 4.28571 9.42857 4.28571C12.2857 4.28571 14.5714 6.57143 14.5714 9.42857C14.5714 12.2857 12.2857 14.5714 9.42857 14.5714C6.57143 14.5714 4.28571 12.2857 4.28571 9.42857Z"
            fill="#8595A1"
          />
        </svg>

        <div class="enter-your-message">Enter your message</div>
      </div>
    </div>

    <div class="group-10471">
      <div class="frame-7885"></div>

      <div class="frame-7886">
        <div class="today">Today</div>
      </div>
    </div>

    <div class="message">
      <div
        class="this-is-what-a-message-from-customer-to-the-agent-will-look-like"
      >
        This is what a message from customer to the agent will look like.
      </div>

      <div class="_12-00-pm2">12:00 PM</div>
    </div>

    <div class="message2">
      <div
        class="this-is-what-a-message-from-customer-to-the-agent-will-look-like2"
      >
        This is what a message from customer to the agent will look like.
      </div>

      <div class="_12-00-pm3">12:00 PM</div>
    </div>

    <div class="frame-7876">
      <div class="frame-7877">
        <div class="enter-message">Enter Message</div>
      </div>

      <div class="frame-7878">
        <svg
          class="ps-attachment"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.1543 1.77966C20.0018 0.627113 18.6035 0.0508423 16.9594 0.0508423C15.3153 0.0508423 13.9001 0.627113 12.7136 1.77966L1.62889 12.9152C0.747532 13.7966 0.306854 14.8305 0.306854 16.0169C0.306854 17.2034 0.747532 18.2373 1.62889 19.1186C2.51024 20 3.52719 20.4407 4.67974 20.4407C5.79838 20.4407 6.79838 20 7.67974 19.1186L18.8662 7.9322C19.3747 7.45762 19.6289 6.81355 19.6289 5.99999C19.6289 5.28813 19.3747 4.62711 18.8662 4.01694C18.3238 3.47457 17.6797 3.20338 16.934 3.20338C16.1882 3.20338 15.578 3.47457 15.1035 4.01694L4.78143 14.339C4.27296 14.8475 4.27296 15.3559 4.78143 15.8644C5.25601 16.4068 5.76448 16.4068 6.30685 15.8644L16.4763 5.54237C16.7475 5.30508 17.0018 5.30508 17.2391 5.54237C17.4763 5.81355 17.4763 6.06779 17.2391 6.30508L6.20516 17.4915C5.76448 17.9322 5.25601 18.1525 4.67974 18.1525C4.10346 18.1525 3.59499 17.9322 3.15431 17.4915C2.71363 17.0508 2.49329 16.5424 2.49329 15.9661C2.49329 15.3898 2.71363 14.8814 3.15431 14.4407L14.1882 3.30508C14.9679 2.52542 15.8831 2.13559 16.934 2.13559C17.9848 2.13559 18.8831 2.52542 19.6289 3.30508C20.3747 4.05084 20.7475 4.94067 20.7475 5.97457C20.7475 7.00847 20.3747 7.91525 19.6289 8.69491L10.0696 18.2542C9.59499 18.7288 9.59499 19.2373 10.0696 19.7797C10.6119 20.2542 11.1204 20.2542 11.595 19.7797L21.1543 10.2203C22.3069 9.03389 22.8831 7.62711 22.8831 5.99999C22.8831 4.37288 22.3069 2.9661 21.1543 1.77966Z"
            fill="#172340"
          />
        </svg>
      </div>

      <div class="frame-7879">
        <svg
          class="ic-round-send"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.4 20.4L20.85 12.92C21.0304 12.8432 21.1842 12.715 21.2923 12.5514C21.4004 12.3879 21.4581 12.1961 21.4581 12C21.4581 11.804 21.4004 11.6122 21.2923 11.4486C21.1842 11.2851 21.0304 11.1569 20.85 11.08L3.4 3.60003C3.2489 3.53412 3.08377 3.50687 2.91951 3.52073C2.75525 3.53459 2.59702 3.58912 2.4591 3.67942C2.32118 3.76971 2.20791 3.89292 2.1295 4.03793C2.0511 4.18293 2.01003 4.34518 2.01 4.51003L2 9.12003C2 9.62003 2.37 10.05 2.87 10.11L17 12L2.87 13.88C2.37 13.95 2 14.38 2 14.88L2.01 19.49C2.01 20.2 2.74 20.69 3.4 20.4Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
/* Code generated with AutoHTML Plugin for Figma */

export default {
  name: "SmsModule",
  components: {},
  props: {},
  data() {
    // quickfix to have components available to pass as props
    return {};
  },
};
</script>
<style scoped>
.sms-module,
.sms-module * {
  box-sizing: border-box;
}
.sms-module {
  background: #f9fafe;
  width: 100%;
  height: 1024px;
  position: relative;
  overflow: hidden;
}
.side-bar {
  width: 387px;
  height: 1024px;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: hidden;
}
.rectangle-4232 {
  background: #eaecef;
  width: 387px;
  height: 1024px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.frame-7884 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 40px;
  top: 316px;
}
.contact {
  background: #ffffff;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-7864 {
  display: flex;
  flex-direction: row;
  gap: 140px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.john-doe {
  color: #172340;
  text-align: left;
  font: 500 16px "Inter", sans-serif;
  position: relative;
}
._12-00-pm {
  color: #9aaab6;
  text-align: left;
  font: 400 14px "Inter", sans-serif;
  position: relative;
}
.this-was-the-last-message-sent {
  color: #9aaab6;
  text-align: left;
  font: 400 14px "Inter", sans-serif;
  position: relative;
}
.contact2 {
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.all-contacts {
  color: #172340;
  text-align: left;
  font: 500 16px/20px "Inter", sans-serif;
  position: absolute;
  left: 40px;
  top: 280px;
}
.frame-7818 {
  background: #ffffff;
  border-radius: 4px;
  border-style: solid;
  border-color: #dfe2e5;
  border-width: 1px;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  width: 307px;
  height: 40px;
  position: absolute;
  left: 40px;
  top: 216px;
}
._24-px-gray-search {
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}
.type-contact-name {
  color: #8595a1;
  text-align: left;
  font: 400 14px/20px "Inter", sans-serif;
  position: relative;
}
.frame-7872 {
  background: #172340;
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 307px;
  height: 40px;
  position: absolute;
  left: 40px;
  top: calc(50% - 360px);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 2px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
}
._24-px-dark-add {
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}
.create-new-message {
  color: #ffffff;
  text-align: left;
  font: 500 14px/20px "Inter", sans-serif;
  position: relative;
}
.frame-7883 {
  display: flex;
  flex-direction: row;
  gap: 97px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 307px;
  position: absolute;
  left: 40px;
  top: calc(50% - 472px);
}
.frame-7882 {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-7880 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.ellipse-1 {
  background: #f5f06e;
  border-radius: 50%;
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  position: relative;
}
.cc {
  color: #172340;
  text-align: left;
  font: 600 24px/20px "Inter", sans-serif;
  position: absolute;
  left: calc(50% - 18px);
  top: calc(50% - 10px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.frame-7881 {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.chris-rocha {
  color: #172340;
  text-align: left;
  font: 500 24px/20px "Inter", sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rocket-flood {
  color: #8595a1;
  text-align: left;
  font: 400 16px/20px "Inter", sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.uiw-edit {
  flex-shrink: 0;
  position: absolute;
  left: 287px;
  top: 10px;
  overflow: visible;
}
.frame-7873 {
  border-style: solid;
  border-color: #dfe2e5;
  border-width: 0px 0px 1px 0px;
  height: 70px;
  position: absolute;
  right: 40px;
  left: 427px;
  top: 40px;
  overflow: hidden;
}
.john-doe2 {
  color: #172340;
  text-align: left;
  font: 500 24px/20px "Inter", sans-serif;
  position: absolute;
  left: 0px;
  top: 25px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.frame-7863 {
  background: #ffffff;
  border-radius: 4px;
  border-style: solid;
  border-color: #dfe2e5;
  border-width: 1px;
  padding: 8px 0px 8px 0px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  top: 15px;
}
.noun-hamburger-1194167-1 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
}
.group {
  position: absolute;
  left: 2px;
  top: 5px;
  overflow: visible;
}
.frame-78182 {
  background: #ffffff;
  border-radius: 4px;
  border-style: solid;
  border-color: #dfe2e5;
  border-width: 1px;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  width: 307px;
  height: 40px;
  position: absolute;
  right: 64px;
  top: 15px;
}
._24-px-gray-search2 {
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}
.enter-your-message {
  color: #8595a1;
  text-align: left;
  font: 400 14px/20px "Inter", sans-serif;
  position: relative;
}
.group-10471 {
  position: absolute;
  inset: 0;
}
.frame-7885 {
  border-style: solid;
  border-color: #dfe2e5;
  border-width: 0px 0px 1px 0px;
  height: 1px;
  position: absolute;
  right: 40px;
  left: 427px;
  top: 623px;
  overflow: hidden;
}
.frame-7886 {
  background: #f9fafe;
  padding: 0px 12px 0px 12px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 71px;
  height: 20px;
  position: absolute;
  left: calc(50% - -158px);
  top: 614px;
}
.today {
  color: #9aaab6;
  text-align: left;
  font: 500 16px/20px "Inter", sans-serif;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.message {
  background: #dfe2e5;
  border-radius: 12px 12px 12px 0px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
  justify-content: center;
  width: 500px;
  height: 100px;
  position: absolute;
  left: 427px;
  top: 658px;
}
.this-is-what-a-message-from-customer-to-the-agent-will-look-like {
  color: #172340;
  text-align: left;
  font: 500 16px/20px "Montserrat", sans-serif;
  position: relative;
  width: 468px;
}
._12-00-pm2 {
  color: #939498;
  text-align: right;
  font: 400 14px/20px "Montserrat", sans-serif;
  position: relative;
}
.message2 {
  background: #172340;
  border-radius: 12px 12px 0px 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
  justify-content: center;
  width: 500px;
  height: 108px;
  position: absolute;
  right: 40px;
  top: 782px;
}
.this-is-what-a-message-from-customer-to-the-agent-will-look-like2 {
  color: #ffffff;
  text-align: left;
  font: 500 16px/20px "Montserrat", sans-serif;
  position: relative;
  width: 468px;
}
._12-00-pm3 {
  color: rgba(255, 255, 255, 0.7);
  text-align: left;
  font: 400 14px/20px "Montserrat", sans-serif;
  position: relative;
}
.frame-7876 {
  border-radius: 0px 0px 8px 8px;
  border-style: solid;
  border-color: #dfe2e5;
  border-width: 1px 0px 0px 0px;
  height: 70px;
  position: absolute;
  right: 40px;
  left: 427px;
  top: 914px;
  overflow: hidden;
}
.frame-7877 {
  background: #eaecef;
  border-radius: 30px;
  padding: 13px 24px 13px 24px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  height: 46px;
  position: absolute;
  right: 140px;
  left: 0px;
  top: 12px;
}
.enter-message {
  color: #8595a1;
  text-align: left;
  font: 400 14px/20px "Inter", sans-serif;
  position: relative;
}
.frame-7878 {
  background: #eaecef;
  border-radius: 30px;
  width: 46px;
  height: 46px;
  position: absolute;
  right: 70px;
  top: 12px;
  overflow: hidden;
}
.ps-attachment {
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
  overflow: visible;
}
.frame-7879 {
  background: #172340;
  border-radius: 30px;
  width: 46px;
  height: 46px;
  position: absolute;
  right: 0px;
  top: 12px;
  overflow: hidden;
}
.ic-round-send {
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
  overflow: visible;
}
</style>
