<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="navigationDrawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-list>
      <v-list-item to="/">
        <v-img src="/img/logo.png" contain />
      </v-list-item>
      <v-divider class="ma-0 py-1" />
      <base-item
        :item="{
          title: 'Profile',
          icon: 'mdi-home',
          to: { name: 'user-profile' },
        }"
      />
    </v-list>

    <v-divider class="ma-0 pa-0" />

    <v-list expand nav>
      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
          class="text-uppercase"
        >
        </base-item-group>

        <template v-else>
          <template v-if="item.notifications">
            <v-badge
              :key="`item-${i}-b`"
              color="green"
              overlap
              left
              :content="item.notifications"
              style="width: 100%"
            >
              <base-item
                :key="`item-${i}`"
                :item="item"
                class="text-uppercase"
              />
            </v-badge>
          </template>
          <template v-else>
            <base-item :key="`item-${i}`" :item="item" class="text-uppercase" />
          </template>
        </template>
      </template>
    </v-list>

    <template v-slot:append>
      <base-item
        :item="{
          title: 'Logout',
          icon: 'mdi-logout',
          to: '/logout',
        }"
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";
export default {
  name: "DashboardCoreDrawer",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    ...mapState(["barColor", "barImage"]),
    items() {
      let navs = [
        {
          icon: "mdi-view-dashboard",
          title: "dashboard",
          to: { name: "dashboard-home" },
        },
      ];
      if (this.currentUserRole == "super-admin") {
        navs = this.$helpers.arrayInsert(navs, 1, {
          icon: "mdi-account-supervisor-circle",
          title: "MGA Management",
          to: { name: "mga-management" },
        });
      }
      if (this.currentUserRole == "admin") {
        // navs = this.$helpers.arrayInsert(navs, 1, {
        // 	icon: "mdi-account-settings",
        // 	title: "API Partners",
        // 	to: { name: "api-management" },
        // 	notifications: this.$store?.getters["auth/user_notifications"]
        // 		?.navigations?.new_customer_count
        // });
        navs = this.$helpers.arrayInsert(navs, 1, {
          icon: "mdi-cog",
          title: "Portal Settings",
          to: { name: "settings" },
        });
        navs = this.$helpers.arrayInsert(navs, 2, {
          icon: "mdi-cog",
          title: "Quotes Portal",
          to: { name: "quotes-portal-management" },
        });
        navs = this.$helpers.arrayInsert(navs, 3, {
          icon: "mdi-table-arrow-up",
          title: "Bulk Upload",
          to: { name: "bulk-upload" },
        });
        navs = this.$helpers.arrayInsert(navs, 4, {
          icon: "mdi-form-select",
          title: "Agent Portal",
          to: { name: "agent-portal" },
        });
      }
      if (this.currentUserRole == "agent") {
        navs = this.$helpers.arrayInsert(navs, 1, {
          icon: "mdi-cog",
          title: "Portal Settings",
          to: { name: "settings" },
        });
        navs = this.$helpers.arrayInsert(navs, 2, {
          icon: "mdi-table-arrow-up",
          title: "Bulk Upload",
          to: { name: "bulk-upload" },
        });
        navs = this.$helpers.arrayInsert(navs, 3, {
          icon: "mdi-form-select",
          title: "Agent Portal",
          to: { name: "agent-portal" },
        });
      }
      return navs;
    },
    navigationDrawer: {
      get() {
        return this.$store.state.navigationDrawer;
      },
      set(val) {
        this.$store.commit("setNavigationDrawer", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: "avatar",
      };
    },
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title,
      };
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
        margin-right: 24px
        margin-left: 12px !important

        +rtl()
        margin-left: 24px
        margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
        padding-left: 8px

        +rtl()
        padding-right: 8px

      .v-list-group__header
        +ltr()
        padding-right: 0

        +rtl()
        padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
          margin-right: 8px

          +rtl()
          margin-left: 8px
</style>
